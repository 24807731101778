import styles from "./Contact.module.css"
import ContactItem from "../../components/ContactItem/ContactItem";
import email from "../../resources/email-logo.png";
import phone from "../../resources/phone-logo.png";
import whatsapp from "../../resources/whatsapp-logo.png";
import time from "../../resources/time-logo.png";
import placeholder from "../../resources/placeholder.png";

export default function Contact(){
    return(
        <main className={styles.main}>
            <h1 className={styles.title}> Contact</h1>
            <div className={styles.container}>
                <ContactItem
                image={email}
                email="camperplaatskleindorp@gmail.com"
                />
                <ContactItem
                    image={placeholder}
                    text={"Doesburgseweg 26"}
                    text2={"7031JC Wehl"}
                />
                <ContactItem
                image={phone}
                phone="06-83620510"
                />
                <ContactItem
                image={time}
                text="Wij zijn bereikbaar tussen 10:00 en 20:00"
                text2="(voor noodgevallen kunt u ons ook buiten deze tijden bereiken)"
                />
                <ContactItem
                    image={whatsapp}
                    text="Ook bereikbaar via whatsapp"
                />
            </div>
        </main>
    )
}