import styles from "./Zwembad.module.css"
import {useEffect, useState} from "react";

export default function Zwembad(){
    const images = importAll(require.context('../../resources/zwembadPictures', false, /\.(png|jpe?g|svg)$/));
    const length = Object.keys(images).length-1
    const [imageCarousel, setImageCarousel] = useState([]);
    const [carouselindex, setCarouselindex] = useState(0);

    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => {
            images[item.replace('./', '')] = r(item);
        });
        return images;
    }

    useEffect(()=>{
        const interval = setInterval(()=>{
            setCarouselindex(carouselindex => carouselindex+1);
        }, 2000);
        return () => clearInterval(interval)
    }, [])

    return(
        <main className={styles.main}>
            <div className={styles.titlecontainer}>
                <h1 className={styles.title}>Zwembad "de Bijvoorde" </h1>
            </div>
            <div className={styles.fotocontainer}>
                <img className={`${styles.image} ${styles.desktop}`}
                     src={images[Object.keys(images)[length-(carouselindex%8)]]}
                     alt={Object.keys(images)[length-(carouselindex%8)]}/>

                <img className={styles.image}
                     src={images[Object.keys(images)[length-((carouselindex+1)%8)]]}
                     alt={Object.keys(images)[length-((carouselindex+1)%8)]}/>

                <img className={styles.image}
                     src={images[Object.keys(images)[length-((carouselindex+2)%8)]]}
                     alt={Object.keys(images)[length-((carouselindex+2)%8)]}/>

                <img className={`${styles.image} ${styles.desktop}`}
                     src={ images[Object.keys(images)[length-((carouselindex+3)%8)]]}
                     alt={Object.keys(images)[length-((carouselindex+3)%8)]}/>

            </div>
            <article className={styles.article}>
                <p>Wehl heeft een prachtig buitenzwembad waar je optimaal kunt genieten van de prachtige zomerzon.</p>
                <p>Kijk voor actuele openingstijden,
                    tarieven en evenementen op <a target="_blank"
                                                  href="https://www.sportinwehl.nl/accommodaties/zwembad-de-byvoorde/">de website</a>
                </p>
            </article>
        </main>
    )
}