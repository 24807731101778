import styles from "./Menubalk.module.css"
import logo from "../../resources/logo.png"
import svrLogo from "../../resources/svrLogo.jpeg"
import hamburger from "../../resources/hamburger.png"
import {NavLink, useHistory} from "react-router-dom";
import MenuItems from "./MenuItems/MenuItems";
import {useState} from "react";

const moveToSvr = () => {
    console.log("doing something");
    window.open("https://www.svr.nl", "_blank");
}
export default function Menubalk(){
    const history = useHistory();
    const [dropdown, toggleDropdown] = useState(false);

    return(
        <main className={styles.main}>
            <div className={styles.icons}>
            <section className={styles.logo} onClick={()=>{history.push("/Home")}}> <img src={logo} alt="logo"/></section>
            <section className={styles.svglogo} onClick={()=>{moveToSvr()}}><img src={svrLogo} alt="SVR-logo"/></section>
            </div>
            <section className={styles.headertextcontainer}>
                <p className={`${styles.headertext} ${styles.headerinvisible}`}>Camperplaats</p>
                <p className={styles.headertext}>Kleindorp</p>
            </section>
            <section className={styles.menus}>
                <MenuItems/>
            </section>
            <section className={styles.mobilemenus}
                     onClick={()=>{console.log(dropdown); toggleDropdown(!dropdown)}}
                     // onMouseLeave={()=>{console.log("leave"); toggleDropdown(false)}}
            >

                <img src={hamburger}/>
                {dropdown &&
                    <MenuItems className={dropdown && styles.invisible} mobile={true}/>

                }

            </section>
        </main>
    )
 }