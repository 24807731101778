import sanitairGebouw from "../resources/publicPictures/toiletBinnen.jpg"
import buiten from "../resources/publicPictures/toiletBuiten.jpg"
import wifiTV from "../resources/schotelOntvangst.jpg"
import plekken from "../resources/publicPictures/campingplaatsVanVoren.jpg"
export const FaciliteitenList =[
    {
        title:"Sanitair gebouw",
        list: ["Douche", "2 Toiletten", "2 Wasbakken (koud & warm)", "Wasmachine"],
        picture: sanitairGebouw
    },
    {
        title:"Buiten",
        list:["Een plaats om uw watertank te vullen", "1 Wasbak (koud)", "Een plaats om uw chemisch toilet te lozen"],
        picture: buiten
    },
    {
        title: "Wifi & TV",
        list: ["Gratis wifi", "Schotel ontvangst"],
        picture: wifiTV
    },
    {
        title: "11 plekken voor campers",
        list: ["Verharde parkeervakken", "6 ampère stroomaansluiting"],
        picture: plekken
    }
]