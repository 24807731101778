import styles from "./MenuItem.module.css"
import {NavLink} from "react-router-dom";
import {useState} from "react";
export default function MenuItem({children, link, styling, mobile}){
    const [visibility, toggleVisibility] = useState(false);

    const handleVisibility = ()=>{
        toggleVisibility(!visibility)
    }
    return(
        <>
            <li className={`${styles.li} ${styles[`${styling}`]}`} onMouseEnter={handleVisibility} onMouseLeave={handleVisibility}>
                <NavLink className={`${styles.unselected} `}
                         activeClassName={styles.selected}
                         to={`/${link}`}
                >{link}</NavLink>
                <section className={styles.children}>
                {!mobile ? visibility && children : children}
                </section>
            </li>
        </>
    )
}