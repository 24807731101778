import styles from "./WelcomeMessage.module.css";
import logoSign from "../../resources/camperplaatsBordCropped.jpg"

export default function WelcomeMessage({visibility, accept}){
    if(!visibility){
        return(<></>)
    }
    else {
        return (
            <div className={styles.welcome} onClick={() => {
                accept();
            }}>
                {console.log(visibility)}
                <div onClick={(e)=>{e.stopPropagation()}} className={styles.welcomeText}>
                    <img className={styles.logoSign} src={logoSign}/>
                    <p>Welkom bij camperplaats Kleindorp</p>
                    <p>Hier vind u alle info over ons en onze regio</p>
                    <p>Of bekijk onze promotievideo en foto’s</p>
                    <p className={styles.info}>U kunt deze video pauzeren door er op te klikken</p>
                    <button type="button"
                            className={styles["accept-button"]}
                            onClick={() => {
                                accept()
                            }}
                    >OK
                    </button>
                </div>

            </div>
        )
    }
}
