import styles from "./Dorpen.module.css"
import {DorpenList} from "../../config/DorpenList";
import Post from "../../components/Post/Post";
export default function Dorpen(){
    let orientationSwitch = false;
    return(
        <>
            <h1 className={styles.title}>Dorpen en steden in de buurt</h1>
            {DorpenList.map((dorp)=>{
                orientationSwitch = ! orientationSwitch;
                return(
                <Post title={dorp.title}
                      list={dorp.list}
                      picture={dorp.picture}
                      orientation={orientationSwitch? "left" : "right"}
                      listStyleNone={true}
                      key={dorp.title}
                      />
                )
            })}
        </>
    )
}