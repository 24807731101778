import styles from "./Text.module.css"
export default function Text({title, list, liststyleNone}){
    return(
        <div className={styles.div}>
            <h2 className={styles.title}>{title}</h2>
            <ul className={liststyleNone? styles.nonlist: styles.list}>
                {list.map((item, index)=>{
                    return(
                    <li className={styles.listitem} key={item+title+index}>{item}</li>
                    )
                })}
            </ul>

        </div>
    )
}