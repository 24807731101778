import styles from "./Post.module.css"
import Text from "./Text/Text";
import Pic from "./Pic/Pic";
export default function Post({title, list, picture, orientation, listStyleNone}){
    return(
        <main className={`${styles.main} ${orientation==="right" ? styles.right : styles.left}`}>
            <Text title={title} list={list} liststyleNone={listStyleNone}/>
            <Pic picture={picture} title={title}/>
        </main>
    )
}