import Menubalk from "./components/Menubalk/Menubalk";
import './App.css';
import {Switch, Route} from "react-router-dom";
import Home from "./pages/Home/Home";
import Faciliteiten from "./pages/Faciliteiten/Faciliteiten";
import Contact from "./pages/Contact/Contact";
import Regio from "./pages/Regio/Regio";
import Tarieven from "./pages/Tarieven/Tarieven";
import {useState} from "react";
import Fotos from "./pages/Fotos/Fotos";
import Wehl from "./pages/Wehl/Wehl";
import Dorpen from "./pages/Dorpen/Dorpen";
import Zwembad from "./pages/Zwembad/Zwembad";

function App() {
    return (
        <div className="App">
            <Menubalk/>
            <Switch>
                <Route path="/Home" >
                    <Home />
                </Route>
                <Route exact path="/" >
                    <Home />
                </Route>
                <Route path="/Faciliteiten">
                    <Faciliteiten/>
                </Route>
                <Route path="/Contact">
                    <Contact/>
                </Route>
                <Route path="/Regio">
                    <Regio/>
                </Route>
                <Route path="/Tarieven">
                    <Tarieven/>
                </Route>
                <Route path="/Fotos">
                    <Fotos/>
                </Route>
                <Route path="/Wehl">
                    <Wehl/>
                </Route>
                <Route path="/Dorpen">
                    <Dorpen/>
                </Route>
                <Route path="/Zwembad">
                    <Zwembad/>
                </Route>
                {/*<Route path='/svr' component={() => {*/}
                {/*    window.location.href = 'https://www.svr.nl';*/}
                {/*    return null;*/}
                {/*}}/>*/}

                <Route path="*"><p>404 not found</p></Route>
            </Switch>
            <div className="footer">
                <p>copyright: Mark Rensen</p>
            </div>
        </div>
    );
}

export default App;
