import styles from "./Tarieven.module.css"
import {PriceList} from "../../config/PriceList"
export default function Tarieven(){
    return(
        <main className={styles.main}>
            <h1 className={styles.title}>Tarieven</h1>
            <p>Prijs per nacht = {PriceList.perNacht}  <br/>
            (voor 1 camper met 2 personen)</p>
            <p>Extra persoon = {PriceList.extraPersoon} per nacht</p>
            <p>Gebruik wasmachine = {PriceList.wasmachine} per wasbeurt</p>
            <h1 className={styles.title}>Huisregels</h1>
            <p>Huisdieren zijn toegestaan, mits aangelijnd</p>
        </main>
    )
}