import styles from "./Wehl.module.css"
import wehl from "../../resources/1200px-Wapen_wehl.png"
export default function Wehl(){
    return(
        <main className={styles.main}>
            <h1 className={styles.title}>Wehl</h1>
            <p>We zitten in de achterhoek natuurlijk in een prachtige omgeving, maar Wehl heeft zelf ook veel te bieden.</p>
            <p>Op onze <a target="_blank" href="https://www.wehl.nl">dorps-website</a> vind je alles wat je weten wil over Wehl of klik op een van onderstaande links</p>
            <div className={styles.centerpiece}>
                <div className={styles.desktopinfo}>
                    <a target="_blank" href="https://www.wehl.nl/product-category/horeca/">Horeca</a>
                    <a target="_blank" href="https://www.wehl.nl/product-category/ondernemers/">Winkels</a>
                </div>
                <img className={styles.wehl} src={wehl} alt="WapenVanWehl"/>
                <div className={styles.desktopinfo}>
                    <a target="_blank" href="https://www.wehl.nl/product-category/bezienswaardigheden/">Bezienswaardigheden</a>
                    <a target="_blank" href="https://www.wehl.nl/routes/">Wandelroutes</a>
                </div>
            </div>
            <div className={styles.mobileinfo}>
                <h2>Handige links:</h2>
                <a target="_blank" href="https://www.wehl.nl/product-category/horeca/">Horeca</a>
                <a target="_blank" href="https://www.wehl.nl/product-category/ondernemers/">Winkels</a>
                <a target="_blank" href="https://www.wehl.nl/product-category/bezienswaardigheden/">Bezienswaardigheden</a>
                <a target="_blank" href="https://www.wehl.nl/routes/">Wandelroutes</a>
            </div>
        </main>
    )
}