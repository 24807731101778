import styles from "./Faciliteiten.module.css"
import {FaciliteitenList} from "../../config/FaciliteitenList";
import Post from "../../components/Post/Post";
import {useState} from "react";
export default function Faciliteiten(){
    // const [orientationSwitch, toggleOrientationSwitch] = useState(false);
    let orientationSwitch = false;
    return(
        <main className={styles.main}>
            {FaciliteitenList.map((item)=>{
                orientationSwitch = !orientationSwitch
                return(
                    <Post title={item.title}
                          list={item.list}
                          picture={item.picture}
                          orientation={orientationSwitch?"left":"right"}
                          key={item.title}
                          />
                )
            })}
            {/*<Post title="sanitair gebouw"*/}
            {/*      list={["douche","2 toiletten","2 wasbakken (koud en warm)", "Wasmachine"]}*/}
            {/*      picture={sanitairGebouw}*/}
            {/*      orientation="left"*/}
            {/*      />*/}
        </main>
    )
}