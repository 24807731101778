import styles from "./Fotos.module.css"
import video from "../../resources/Kleindorp-Camping-Promotion-Video.mp4"
export default function Fotos(){
    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => {
            images[item.replace('./', '')] = r(item);
        });
        return images;
    }

    const images = importAll(require.context('../../resources/publicPictures', false, /\.(png|jpe?g|svg)$/));



    return(
        <div>
            <div className={styles.videocontainer}>
                <h3 className={styles.title}>Bekijke onze promotie video</h3>
                <video className={styles.video} controls>
                    <source src={video} type="video/mp4"/>
                </video>
            </div>
            <div className={styles.fotocontainer}>
                <h3 className={styles.title}>Bekijke onze foto's</h3>
                {Object.entries(images).map(([name, image])=>{
                    return(
                        <img className={styles.foto} src={image} alt={name} key={name}/>
                    )
                })}
            </div>
        </div>
    )
}