import styles from "./ContactItem.module.css"
export default function ContactItem({image, text, text2, phone, email}){
    return(
        <div className={styles.main}>
            <img className={styles.logo} src={image}/>
            <div className={styles.textcontainer}>
            {text && <p className={styles.text}>{text}</p>}
            {text2 && <p className={styles.text2}>{text2}</p>}
            </div>
            {phone && <a href={`tel: ${phone}`}>telefoon: {phone}</a> }
            {email && <a href={`mailto: ${email}`}>email: {email}</a>}
        </div>
    )
}