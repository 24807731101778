import styles from "./Regio.module.css"
import {Link} from "react-router-dom";
import liemersvlag from "../../resources/liemersvlag.png"
import achterhoekvlag from "../../resources/260px-Vlag-Achterhoek.jpg"
export default function Regio(){
    return(
        <main className={styles.main}>
            <div>
                <h1 className={styles.title}>Op de grens van de achterhoek en de liemers</h1>
                <p>Kijk voor meer informatie op <a target="_blank" href="https://www.achterhoek.nl">achterhoek.nl</a></p>
                <p>of kijk op <a target="_blank" href="https://www.deliemershelemaalgoed.nl">deliemershelemaalgoed.nl</a></p>
            </div>
            <div className={styles.pictures}>
                <div>
                <img className={styles.vlag} src={achterhoekvlag} alt="achterhoekvlag"/>
                </div>
                <div>
                <img className={styles.vlag} src={liemersvlag} alt="liemersvlag"/>
                </div>
            </div>

            <ul className={styles.ul}>
                <li>Meer informatie over de regio:</li>
                <li><Link to="/Wehl">Wehl</Link></li>
                <li><Link to="/Zwembad">Zwembad de Bijvoorde</Link></li>
                <li><Link to="/Dorpen">Dorpen en Steden in de buurt</Link></li>
            </ul>
        </main>
    )
}