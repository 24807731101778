import styles from "./Home.module.css"
import video from "../../resources/Kleindorp-Camping-Promotion-Video.mp4"
import {useRef, useState} from "react";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
export default function Home(){
    const [welcomeMessage, toggleWelcomeMessage] = useState(true);
    const vidRef = useRef(null);
    const handlePlayVideo = () => {
        if(vidRef.current.paused) {
            vidRef.current.play();
        } else {
            vidRef.current.pause();
        }
    }
    function acceptFunction(){
        toggleWelcomeMessage(false);
        handlePlayVideo();
    }
    return(
        <>
            <div onClick={()=>{handlePlayVideo()}} className={styles.videocontainer}>
                <p className={!welcomeMessage? styles.videoinfo : styles.invisible}>u kunt op het scherm klikken om de video te pauzeren</p>
                <video ref={vidRef} id="video" className={styles.video}  loop muted>
                    <source src={video} type="video/mp4"/>
                </video>
            </div>
            <WelcomeMessage visibility={welcomeMessage} accept={()=>{acceptFunction()}}/>
        </>
    )
}