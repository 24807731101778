import styles from './MenuItems.module.css';
import {MenuItemList} from "../../../config/MenuItemList";
import MenuItem from "../MenuItem/MenuItem";
import Dropdown from "../Dropdown/Dropdown";
import {useState} from "react";

export default function MenuItems({mobile}){

    {
        if (mobile) {
            const tempList = [];
            MenuItemList.map((item)=>{
                tempList.push(item.title);
                if(item.subs){
                    item.subs.map((sub)=>{
                        tempList.push(sub)
                    })
                }
            })
            return (
                <ul className={styles.ul}>
                    {tempList.map((item) => {
                        return (
                            <nav key={item}>
                                <MenuItem link={item}/>
                            </nav>
                        )
                    })}
                </ul>
            )
        } else {
            return (
                <ul className={styles.ul}>
                    {/*<li>*/}

                    {/*        <NavLink className={styles.unselected}*/}
                    {/*                 activeClassName={styles.selected}*/}
                    {/*                 to="/Home"*/}

                    {/*        >Home</NavLink>*/}
                    {/*    {visibility &&*/}
                    {/*    <li><NavLink className={visibility ? styles.submenu : styles.invisible}*/}
                    {/*                 to={"/Fotos"}>Fotos</NavLink></li>*/}
                    {/*    }*/}
                    {/*</li>*/}

                    {MenuItemList.map((item) => {
                            return (
                                <nav key={item.title}>
                                    <MenuItem link={item.title}>
                                        {item.subs && <Dropdown names={item.subs} mobile={mobile} key={item.subs[0]}/>}
                                    </MenuItem>
                                </nav>
                            )

                    })}

                    {/*<MenuItem link="Home">*/}
                    {/*        <Dropdown names={["Fotos"]}/>*/}
                    {/*</MenuItem>*/}
                    {/*<MenuItem link="Faciliteiten"/>*/}
                    {/*<MenuItem link="Regio">*/}
                    {/*        <Dropdown names={["Wehl", "Zwembad", "Dorpen en Steden"]}/>*/}
                    {/*        /!*<MenuItem styling="submenu" link="Wehl"/>*!/*/}
                    {/*        /!*<MenuItem styling="submenu" link="Zwembad"/>*!/*/}
                    {/*        /!*<MenuItem styling="submenu" link="Dorpen en Steden"/>*!/*/}
                    {/*</MenuItem>*/}
                    {/*<MenuItem link="Tarieven"/>*/}
                    {/*<MenuItem link="Contact"/>*/}
                </ul>
            )
        }
    }
}