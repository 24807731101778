import bronckhorst from "../resources/dorpen/bronckhorst.jpg"
import dieren from "../resources/dorpen/dieren.jpg"
import doesburg from "../resources/dorpen/doesburg.jpg"
import doetinchem from "../resources/dorpen/doetinchem.jpg"
import sheerenberg from "../resources/dorpen/sheerenberg.jpg"
import zeddamkilder from "../resources/dorpen/zeddamkilder.jpg"
export const DorpenList = [
    {
        title:"Bronckhorst",
        list:["Het kleinste stadje van Nederland ligt slechts 16km van onze camperplaats. Een echte touristische trekpleister.", "" +
        "Kijk voor meer informatie op:", <a target="_blank" href="https://www.ontdekbronckhorst.nl/">ontdekbronckhorst.nl</a> ],
        picture: bronckhorst
    },
    {
        title: "Doesburg",
        list: ["Doesburg, een echte oude hanzestad, vol met monumenten en musea, ligt nog geen 10km van onze camperplaats.",
            "Kijk voor meer informatie op:", <a target="_blank" href="https://www.bezoek-doesburg.nl/">bezoek-doesburg.nl</a>],
        picture: doesburg
    },
    {
        title: "Dieren",
        list: ["Dieren ligt een kleine 16km van onze camperplaats. Een prachtig uitganspunt voor mooie wandelingen langs de Ijssel of over de Veluwe"],
        picture: dieren
    },
    {
        title: "Doetinchem",
        list: ["Doetinchem is het centrum en tevens de hoofdstad van de Achterhoek. In Doetinchem kunt u terecht voor een grote variatie aan winkels en horeca. Doetinchem is slechts 15 minuten fietsen van onze camperplaats",
            "Kijk voor meer informatie op:", <a target="_blank" href="https://www.vvvdoetinchem.nl/">vvvdoetinchem.nl</a>],
        picture: doetinchem
    },
    {
        title: "Zeddam en Kilder",
        list: ["Zeddam en Kilder liggen ten zuiden van Wehl, aan de grens van natuurgebied Montferland en zijn daarom een ideaal uitganspunt voor mooie wandelingen."],
        picture: zeddamkilder
    },
    {
        title: "'s-Heerenberg",
        list: ["Kasteelstad 's-Heerenberg ligt zo'n 14 km van onze kamperplaats. Bezoek kasteel Huis Bergh, de oude molen of de uitkijktoren. 's-Heerenberg straalt van de historie, maar ook in de moderne tijd is het een mooi dorp om te bezoeken.",
            "Kijk voor meer informatie op:", <a target="_blank" href="https://www.kasteelstad.nl/">kasteelstad.nl</a>, "of kijk op:", <a target="_blank" href="https://www.huisbergh.nl/">huisbergh.nl</a> ],
        picture: sheerenberg
    }
]